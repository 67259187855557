<template>
    <div class="px-20 pb-20" v-loading="loading" element-loading-background="#00000088">
        <div class="d-flex-center px-15 border-bottom">
            <h3 class="mr-auto">Subscribe Involved</h3>
        </div>
        <div>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-15 border-bottom font-14">
                <el-col :span='2'>Coin</el-col>
                <el-col :span='5'>Price</el-col>
                <el-col :span='5'>Amount</el-col>
                <el-col :span='5'>Lock Period</el-col>
                <el-col :span='5'>Thaw Interval</el-col>
                <el-col :span='5'>Thaw Date</el-col>
                <el-col :span='5' class="text-right">State</el-col>
            </el-row>
            <el-row v-for="(item, index) in record.data" :key="index" :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-15 border-bottom font-14">
                <el-col :span='2'>{{ item.coin }}</el-col>
                <el-col :span='5'>{{ item.price }}</el-col>
                <el-col :span='5'>{{ item.amount }}</el-col>
                <el-col :span='5'>{{ item.cycle }}Month(s)</el-col>
                <el-col :span='5'>{{ item.thaw }}Hour(s)</el-col>
                <el-col :span='5'>{{ item.end_date }}</el-col>
                <el-col :span='5' class="text-right">
                    <el-tag :type="state[item.state][0]" effect="plain" hit class="bg-t">{{ state[item.state][1] }}</el-tag>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            record: {
                data: []
            },
            state: [
                ['primary', 'Frozen'],
                ['success', 'Thaw']
            ]
        }
    },
    created: function() {
        this.fatchRecord()
    },
    methods: {
        fatchRecord: function () {
            this.loading = true
            this.$request.get('coin/buy_log').then(({ data }) => {
                this.record = data
                this.loading = false
            })
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>